import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import useMediaQuery, { BreakPointToken } from '../../hooks/use-media-query';
import ContainerComponent from '../container/container.component';
import {
  TypographyComponent,
  TypographyVariant,
  TypographySize,
  TypographyWeight,
} from '@vaa-component-lib/component.atom.typography';
import getIcon, { IconsSize } from '../icons';

import styles from './countdown-banner.component.module.less';
import { hardRedirectTo } from 'src/utils';

interface CountdownBannerComponentProps {
  startTime: number;
  endTime: number;
  icon?: string;
  bodyText: string;
  bodySubtext: string;
  ctaUrl: string;
  target?: string;
}

interface CountdownClockComponentProps {
  localTime: number;
  endTime: number;
}

interface TimeLeft {
  Days: number;
  Hrs: number;
  Mins: number;
  Secs: number;
}

const CountdownClockComponent = ({
  localTime: now,
  endTime,
}: CountdownClockComponentProps) => {
  const tickCount = useRef<number>(0);
  const [timeLeft, setTimeLeft] = useState<TimeLeft>({
    Days: -1,
    Hrs: -1,
    Mins: -1,
    Secs: -1,
  });

  const multipeLabel = (label: string, number: number): string => {
    const isMultiple = number !== 1;
    const labelHasS = label.endsWith('s');

    return isMultiple && labelHasS ? label : label.slice(0, -1);
  };

  useEffect(() => {
    const tick = setInterval(() => {
      const currentTime = now - tickCount.current * -1000;
      const timeDifference = endTime - currentTime;

      const days = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
      const hours = Math.floor(timeDifference / (60 * 60 * 1000)) % 24;
      const minutes = Math.floor(timeDifference / (60 * 1000)) % 60;
      const seconds = Math.floor(timeDifference / 1000) % 60;

      setTimeLeft({ Days: days, Hrs: hours, Mins: minutes, Secs: seconds });

      tickCount.current++;
    }, 1000);

    return () => clearInterval(tick);
  }, [now, endTime]);

  const { Days: days, Hrs: hours, Mins: minutes, Secs: seconds } = timeLeft;

  const label =
    days +
    ' ' +
    multipeLabel('days', days) +
    ', ' +
    hours +
    ' ' +
    multipeLabel('hours', hours) +
    ', ' +
    minutes +
    ' ' +
    multipeLabel('minutes', minutes) +
    ', and ' +
    seconds +
    ' ' +
    multipeLabel('seconds', seconds) +
    ' remaining.';

  return (
    <div role="timer" aria-label={label} className={styles['countdown-clock']}>
      <ol aria-hidden="true" className={styles['countdown-clock__list']}>
        {Object.entries(timeLeft).map(([key, value], i) => (
          <li
            key={`${i}-{key}`}
            className={classnames(styles['countdown-clock__panel'], {
              [styles['countdown-clock__panel--disabled']]: value < 0,
            })}
          >
            <TypographyComponent
              element="span"
              variant={TypographyVariant.Body}
              size={TypographySize.Sml}
              weight={TypographyWeight.Medium}
            >
              {value < 10 && '0'}
              {value}
            </TypographyComponent>
            <TypographyComponent
              element="span"
              variant={TypographyVariant.Body}
              size={TypographySize.Xsm}
              weight={TypographyWeight.Regular}
            >
              {multipeLabel(key, value)}
            </TypographyComponent>
          </li>
        ))}
      </ol>
    </div>
  );
};

const CountdownBannerComponent = ({
  startTime,
  endTime,
  icon,
  bodyText,
  bodySubtext,
  ctaUrl,
  target,
}: CountdownBannerComponentProps) => {
  const tickCount = useRef<number>(1);
  const isDesktop = useMediaQuery(`(min-width: ${BreakPointToken.SmMax}px)`);
  const [localTime, setLocalTime] = useState<number>(0);
  const [visibleFrame, setVisibleFrame] = useState<number>(1);
  const [pausedTicks, setPausedTicks] = useState<boolean>(false);

  useEffect(() => {
    const bodyEl = document.body;

    const handleFlyoutOpen = () => setPausedTicks(true);
    const handleFlyoutClose = () => setPausedTicks(false);

    setLocalTime(new Date().getTime());
    bodyEl.addEventListener('OPEN_FLYOUT', handleFlyoutOpen);
    bodyEl.addEventListener('CLOSE_FLYOUT', handleFlyoutClose);

    return () => {
      bodyEl.removeEventListener('OPEN_FLYOUT', handleFlyoutOpen);
      bodyEl.removeEventListener('CLOSE_FLYOUT', handleFlyoutClose);
    };
  }, []);

  useEffect(() => {
    if (!isDesktop && !pausedTicks) {
      const frameTick = setInterval(() => {
        const mono = (tickCount.current + 1) % 3;

        if (mono === 1) {
          setVisibleFrame(4);
          setTimeout(() => setVisibleFrame(1), 400);
        } else {
          setVisibleFrame(mono === 0 ? 3 : mono);
        }
        tickCount.current++;
      }, 4500);

      return () => clearInterval(frameTick);
    }
  }, [isDesktop, pausedTicks]);

  if (localTime < startTime || localTime > endTime) {
    return null;
  }

  const IconChild = icon ? getIcon(icon) : null;

  return (
    <section className={styles['countdown-banner']}>
      <div className={styles['countdown-banner__link']}>
        <ContainerComponent>
          <div
            className={classnames(styles['countdown-banner__inner'], {
              [styles['countdown-banner__inner--step-1']]: visibleFrame === 1,
              [styles['countdown-banner__inner--step-2']]: visibleFrame === 2,
              [styles['countdown-banner__inner--step-3']]: visibleFrame === 3,
              [styles['countdown-banner__inner--reset']]: visibleFrame === 4,
            })}
            onClick={(e) => {
              e.preventDefault();
              if (target === '_blank') {
                window.open(ctaUrl, '_blank');
              } else {
                hardRedirectTo(ctaUrl);
              }
            }}
            onKeyDown={(e) => {
              const { key } = e;
              if (key === 'Enter' || key === ' ') {
                e.preventDefault();
                if (target === '_blank') {
                  window.open(ctaUrl, '_blank');
                } else {
                  hardRedirectTo(ctaUrl);
                }
              }
            }}
            tabIndex={0}
          >
            <div className={styles['countdown-banner__frame']}>
              {IconChild && (
                <span className={styles['countdown-banner__icon']}>
                  <IconChild size={IconsSize.Med} />
                </span>
              )}
              <TypographyComponent
                variant={TypographyVariant.Body}
                size={TypographySize.Sml}
                weight={TypographyWeight.Regular}
                element="span"
              >
                {bodyText}
              </TypographyComponent>
            </div>
            <div className={styles['countdown-banner__frame']}>
              <CountdownClockComponent
                localTime={localTime}
                endTime={endTime}
              />
            </div>
            <div className={styles['countdown-banner__frame']}>
              <TypographyComponent
                variant={TypographyVariant.Body}
                size={TypographySize.Sml}
                weight={TypographyWeight.Regular}
                element="span"
              >
                {bodySubtext}
              </TypographyComponent>
            </div>
          </div>
        </ContainerComponent>
      </div>
    </section>
  );
};

export default CountdownBannerComponent;
