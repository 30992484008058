import styles from './logo-bar.component.module.less';
import classnames from 'classnames';
import vholsLogo from 'src/assets/images/logos/virgin-atlantic-holidays.svg';
import vaaLogo from 'src/assets/images/logos/virgin-atlantic.svg';

import Image from 'next/legacy/image';
import { BRANDS } from 'src/utils/ssr-helper';
import { BRAND_NAMES } from 'src/utils/pageheader';
import { hardRedirectTo } from 'src/utils';

interface LogoBarProps {
  brand: BRANDS;
}

const LogoBar = ({ brand }: LogoBarProps) => {
  const logo = brand === BRANDS.VHOLS ? vholsLogo.src : vaaLogo.src;
  const altText = brand === BRANDS.VHOLS ? BRAND_NAMES.VHOLS : BRAND_NAMES.VAA;

  return (
    <div className={classnames(styles['logo-bar'])}>
      <div
        className={classnames(
          styles.logo,
          brand === BRANDS.VAA && styles['vaa']
        )}
      >
        <Image
          priority
          src={logo}
          alt={altText}
          objectFit="cover"
          layout="fill"
          style={{ cursor: 'pointer' }}
          onClick={() => hardRedirectTo('/')}
        />
      </div>
    </div>
  );
};

export default LogoBar;
