import React, { useMemo, useRef, useEffect } from 'react';
import AlertCard, { AlertCardProps } from '../alert-card/alert-card.component';
import styles from './article-list-container.component.module.less';
import useToggleState from 'src/hooks/use-toggle-state';
import ArticleCard, {
  ArticleCardProps,
} from '../article-card/article-card.component';
import { layoutTemplates } from './article-list-container.config';
import { BreakPointToken } from '@virgin-atlantic/component-lib';
import useMediaQuery from 'src/hooks/use-media-query';
import { TypographyComponent } from '@vaa-component-lib/component.atom.typography';
import classNames from 'classnames';
import SeeAllButton from './see-all-button.component';
import {
  createMosaicClasses,
  isIndexEven,
  numberToAlphabet,
} from 'src/utils/component-utils/article-grid';

interface ArticleCardContainerProps {
  articleItems: ArticleCardProps[] | AlertCardProps[];
  fallbackText?: string;
  componentId: string;
  displayAs: 'notification' | 'default';
  templates: {
    [key: string]: {
      [key: string]: {
        css: {
          odd: string[];
          even: string[];
        };
        gridTemplateColumns: string;
        gridTemplateRows: string;
        grid: { odd: number[][]; even?: number[][] };
      };
    };
  };
}

function splitArray(array: any[], splitFromEnd: number) {
  const index = array.length - splitFromEnd;
  const firstPart = array.slice(0, index);
  const secondPart = array.slice(index);
  return [firstPart, secondPart];
}

const ArticleListContainer: React.FC<ArticleCardContainerProps> = (
  props: ArticleCardContainerProps
) => {
  const {
    articleItems = [],
    displayAs = 'notification',
    templates = layoutTemplates,
    fallbackText,
    componentId,
  } = props;

  const [isSeeAll, setIsSeeAll] = useToggleState(false);
  const containerRef = useRef<HTMLElement | null>(null);
  const Card = useMemo(
    () => (displayAs === 'notification' ? AlertCard : ArticleCard),
    [displayAs]
  );
  const showAllCount = 4;

  const layout = displayAs === 'notification' ? 'grid' : 'mosaic';
  let breakpoints: { [key: string]: number[] } = {
    tablet: [BreakPointToken.SmMin, BreakPointToken.MdMax],
    desktop: [BreakPointToken.LgMin],
    mobile: [],
  };
  if (layout === 'grid') {
    breakpoints = {
      tablet: [BreakPointToken.MdMin, BreakPointToken.LgMax],
      desktop: [BreakPointToken.LgMax + 1],
      mobile: [],
    };
  }

  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop[0]}px)`);
  const isTablet = useMediaQuery(
    `(min-width: ${breakpoints.tablet[0]}px) and (max-width: ${breakpoints.tablet[1]}px)`
  );

  const templateResponsive = isDesktop
    ? 'desktop'
    : isTablet
    ? 'tablet'
    : 'mobile';

  const template = templates[templateResponsive][layout];

  const { grid } = template;
  const rowbreak = grid.odd[0].length;
  const visibleCards = isSeeAll
    ? articleItems
    : articleItems.slice(0, rowbreak >= 4 ? rowbreak : 4);

  const lastRowItems = visibleCards.length % rowbreak;

  let leadingArray = visibleCards;
  let endingArray = [];

  if (layout === 'mosaic') {
    [leadingArray, endingArray] = splitArray(visibleCards, lastRowItems);
  }

  const rows = Math.ceil(leadingArray.length / rowbreak);
  let templateAreas = createMosaicClasses({
    grid: grid,
    rows,
    items: visibleCards,
    rowbreak,
    isDesktop,
    isTablet,
  });

  const mosaicStyles = {
    gridTemplateColumns: template.gridTemplateColumns,
    gridTemplateRows: template.gridTemplateRows,
    gridTemplateAreas: templateAreas,
  };

  if (articleItems.length === 0 && fallbackText === '') {
    return null;
  }

  return (
    <section
      ref={containerRef}
      id={componentId}
      data-testid={`articleslist${componentId ? `-${componentId}` : ''}`}
      className={classNames(
        styles['article-card-container'],
        styles[`article-card-container__${layout}`]
      )}
    >
      <div className={styles['article-card-container__content']}>
        {articleItems.length === 0 ? (
          <div
            style={{
              paddingBottom: articleItems.length > showAllCount ? '72px' : '',
            }}
            className={classNames(
              styles[`article-card-container__${layout}__gap`],
              'article-card-container__gap'
            )}
          >
            <TypographyComponent element="h2">
              {fallbackText}
            </TypographyComponent>
          </div>
        ) : (
          <div
            style={{
              paddingBottom: articleItems.length > showAllCount ? '72px' : '',
            }}
            className={classNames(
              styles[`article-card-container__${layout}__gap`],
              'article-card-container__gap'
            )}
          >
            <div
              className={styles['article-card-container__list']}
              style={{ ...mosaicStyles }}
              data-testid="article-card-list"
            >
              {leadingArray.map((card: any, index) => {
                const isEven = isIndexEven(index, rowbreak);
                const itemClassType = template.css[isEven ? 'even' : 'odd'];
                const itemClass =
                  styles[
                    `article-card-container__${itemClassType[index % rowbreak]}`
                  ];

                let gridArea = `card${numberToAlphabet(
                  Math.ceil((index + 1) / rowbreak)
                )}${numberToAlphabet((index % rowbreak) + 1)}`;

                if (layout === 'grid' && articleItems.length <= 2) {
                  gridArea = `span 2 / ${gridArea}`;
                }

                return (
                  <div
                    data-testid={'article-card'}
                    data-size={itemClassType[index % rowbreak]}
                    key={index}
                    className={classNames(itemClass)}
                    style={{ gridArea }}
                  >
                    <Card {...card} />
                  </div>
                );
              })}
            </div>
            {endingArray.length > 0 && (
              <div
                className={classNames(
                  styles['article-card-container__footer-cards'],
                  {
                    [styles['article-card-container__footer-cards__desktop']]:
                      isDesktop,
                    [styles['article-card-container__footer-cards__tablet']]:
                      isTablet,
                    [styles[
                      'article-card-container__footer-cards__desktop__one'
                    ]]: endingArray.length === 1 && isDesktop,
                    [styles[
                      'article-card-container__footer-cards__desktop__two'
                    ]]: endingArray.length === 2 && isDesktop,
                    [styles[
                      'article-card-container__footer-cards__desktop__three'
                    ]]: endingArray.length === 3 && isDesktop,
                    [styles[
                      'article-card-container__footer-cards__tablet__one'
                    ]]: endingArray.length === 1 && isTablet,
                    [styles[
                      'article-card-container__footer-cards__tablet__two'
                    ]]: endingArray.length === 2 && isTablet,
                    [styles[
                      'article-card-container__footer-cards__tablet__three'
                    ]]: endingArray.length === 3 && isTablet,
                  }
                )}
              >
                {endingArray.map((card: any, index) => {

                  return (
                    <div
                      data-testid={'article-card'}
                      key={index}
                      className={classNames(
                        `footer-card-${index + 1}`,
                        styles[
                          'article-card-container__footer-cards__desktop__footer-card'
                        ],
                        styles[
                          `article-card-container__footer-cards__desktop__three__footercard-${
                            index + 1
                          }`
                        ]
                      )}
                    >
                      <Card {...card} />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
        {articleItems.length > showAllCount && (
          <SeeAllButton isSeeAll={isSeeAll} setIsSeeAll={setIsSeeAll} />
        )}
      </div>
    </section>
  );
};

export default ArticleListContainer;
