import {
  IconNavigationChevronDownComponent,
  IconNavigationChevronUpComponent,
} from '@vaa-component-lib/component.atom.icons';
import styles from './article-list-container.component.module.less';
import {
  ButtonComponent as Button,
  ButtonColour,
  ButtonType,
  IconPosition,
} from '@vaa-component-lib/component.atom.button';

export interface SeeAllButtonProps {
  isSeeAll: boolean;
  setIsSeeAll(): void;
  prefix?: string;
}

const SeeAllButton = ({
  isSeeAll,
  setIsSeeAll,
  prefix = 'See',
}: SeeAllButtonProps) => (
  <div className={styles['article-card-container__cta']}>
    <Button
      data-testid="see-all-button"
      type={ButtonType.Link}
      onClick={setIsSeeAll}
      aria-expanded={isSeeAll}
      aria-controls="article-card-list"
      colour={ButtonColour.Contrast}
      iconPosition={IconPosition.Right}
      iconChild={
        isSeeAll ? (
          <IconNavigationChevronUpComponent />
        ) : (
          <IconNavigationChevronDownComponent />
        )
      }
    >
      {`${prefix} ${isSeeAll ? 'fewer' : 'all'} `}
    </Button>
  </div>
);

export default SeeAllButton;
