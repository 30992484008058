import {
  IconNavigationChevronArrowRightComponent,
  IconsSize,
} from '@vaa-component-lib/component.atom.icons';

import styles from './breadcrumbs.component.module.less';
import useMediaQuery from 'src/hooks/use-media-query';
import classNames from 'classnames';
import { hardRedirectTo } from 'src/utils';

export interface BreadcrumbProps {
  links: BreadcrumbItem[];
  componentId?: string;
  space?: boolean;
}
export interface BreadcrumbItem {
  label: string;
  url: string;
}

const getIcon = (index: number, length: number) => {
  if (index === length - 1) {
    return null;
  }
  return <IconNavigationChevronArrowRightComponent size={IconsSize.Xxs} />;
};

const Breadcrumbs = ({
  links,
  componentId: id = 'default',
  space = false,
}: BreadcrumbProps) => {
  const isMobile = useMediaQuery('(max-width: 766px)');

  if (isMobile || !links || links.length === 0) {
    return null;
  }

  return (
    <nav
      aria-label="breadcrumb"
      data-testid={`breadcrumbs-${id}`}
      id={`breadcrumbs-${id}`}
      className={styles['breadcrumbs']}
      style={{ margin: space ? '32px 0 32px' : '0' }}
    >
      <ol className={styles['breadcrumbs__list']}>
        {links.map((item: BreadcrumbItem, index: number) => {
          const isLast = index === links.length - 1;
          if (!isLast) {
            return (
              <li
                key={`breadcrumb-${id}-${index}`}
                className={styles['breadcrumbs__item']}
              >
                <div
                  onClick={() => hardRedirectTo(item.url)}
                  className={classNames(
                    styles['breadcrumbs__link'],
                    styles['breadcrumbs__button-component'],
                    styles['breadcrumbs__clickable']
                  )}
                  data-testid={`breadcrumb-${id}-${index}`}
                >
                  {item.label}
                  {getIcon(index, links.length)}
                </div>
              </li>
            );
          } else {
            return (
              <li
                className={classNames(
                  styles['breadcrumbs__item'],
                  styles['breadcrumbs__current']
                )}
                key={`breadcrumb-${id}-${index}`}
                data-testid={`breadcrumb-${id}-${index}`}
                aria-current="page"
              >
                {item.label}
              </li>
            );
          }
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
