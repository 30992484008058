import {
  TypographyComponent,
  TypographySize,
  TypographyWeight,
} from '@vaa-component-lib/component.atom.typography';
import classnames from 'classnames';
import styles from './article-card.component.module.less';
import RenderOnScroll from '../render-on-scroll/render-on-scroll.component';
import { SkeletonLoaderComponent } from '@vaa-component-lib/component.atom.skeleton-loader';
import { appendDomainToImageRenditions } from 'src/utils/component-utils/utils';
import { hardRedirectTo } from 'src/utils';

const ArticleLoadingComponent = () => (
  <SkeletonLoaderComponent loading={true} width="100%">
    <div style={{ height: '300px' }}></div>
  </SkeletonLoaderComponent>
);

export interface ArticleCardProps {
  index?: number;
  imagePath: string;
  renditions?: {
    mobile?: string;
    tablet?: string;
    avif?: string;
    webp?: string;
  };
  lastUpdated: string;
  path: string;
  title: string;
  className?: string;
}

export default function ArticleCard(props: ArticleCardProps) {
  const {
    title,
    path,
    imagePath,
    renditions = {
      mobile: '',
      tablet: '',
      avif: '',
      webp: '',
    },
    className = '',
    index = 0,
  } = props;

  const imageRenditions = appendDomainToImageRenditions({
    url: imagePath,
    alt: '',
    renditions,
  });

  return (
    <div
      onClick={() => hardRedirectTo(path)}
      className={classnames(styles['article-card'], className)}
      style={{ cursor: 'pointer' }}
      role="link"
      aria-label={`${title}`}
    >
      <RenderOnScroll
        id={`article-card-${index}`}
        loader={<ArticleLoadingComponent />}
      >
        <picture
          className={styles['article-card__background']}
          aria-hidden="true"
        >
          {imageRenditions.renditions.avif && (
            <source
              srcSet={imageRenditions.renditions.avif}
              type="image/avif"
            />
          )}
          {imageRenditions.renditions.webp && (
            <source
              srcSet={imageRenditions.renditions.webp}
              type="image/webp"
            />
          )}
          {imageRenditions.renditions.mobile && (
            <source
              srcSet={imageRenditions.renditions.mobile}
              media="(max-width: 767px)"
            />
          )}
          {imageRenditions.renditions.tablet && (
            <source
              srcSet={imageRenditions.renditions.tablet}
              media="(max-width: 1280px)"
            />
          )}
          <img
            src={imagePath}
            alt={title}
            className={styles['article-card__image']}
          />
          <span className={styles['article-card__sr-only']}>{title}</span>
        </picture>
      </RenderOnScroll>
      <article className={styles['article-card__content']} role="article">
        <div className={styles['article-card__title']}>
          <TypographyComponent
            element="h3"
            size={TypographySize.Xlg}
            weight={TypographyWeight.Medium}
          >
            {title}
          </TypographyComponent>
        </div>
      </article>
    </div>
  );
}
