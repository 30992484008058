import React from 'react';
import { FocalPoint } from '@vaa-component-lib/component.molecule.hero-banner';
import { ColourOptions, PromoBannerComponent } from '@vaa-component-lib/component.molecule.promo-banner';
import { appendDomainToImageRenditions } from '../../utils/component-utils/utils';

interface Image {
  alt: string;
  url: string;
  dimensions?: {
    width?: number;
    aspectRation?: string;
    height?: number;
  };
  renditions?: {
    mobile?: string;
    tablet?: string;
    avif?: string;
    webp?: string;
  };
}

interface Button {
  text: string;
  href: string;
}

interface PromoBannerProps {
  partnerImage?: Image;
  desktopImage: Image;
  mobileImage: Image;
  buttons?: Button[];
  textIsRich?: string;
  titleSize?: 'Sml' | 'Mdm' | 'Lrg';
  title: string;
  focalPoint?: FocalPoint;
  text: string;
  backgroundColour?: ColourOptions;
  isFullWidth?: boolean;
}

const PromoBanner = (props: PromoBannerProps) => {
  const updatedPartnerImage = props.partnerImage ? appendDomainToImageRenditions(props.partnerImage) : undefined;
  const updatedDesktopImage = appendDomainToImageRenditions(props.desktopImage);
  const updatedMobileImage = appendDomainToImageRenditions(props.mobileImage);

  return (
    <PromoBannerComponent
      {...props}
      partnerImage={updatedPartnerImage}
      desktopImage={updatedDesktopImage}
      mobileImage={updatedMobileImage}
    />
  );
};

export default PromoBanner;
