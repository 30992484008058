interface SVGProps {
    svgString: string;
}

const SVGRenderer: React.FC<SVGProps> = ({ svgString }) => {
    const svgMarkup = { __html: svgString };

    return <div dangerouslySetInnerHTML={svgMarkup} />;
};

export default SVGRenderer;