import {
  TypographyComponent,
  TypographySize,
  TypographyWeight,
} from '@vaa-component-lib/component.atom.typography';
import styles from './alert-card.component.module.less';
import { IconContactNewsComponent } from '@vaa-component-lib/component.atom.icons';
import HTMLRenderer from '../html-renderer/html-renderer.component';
import { hardRedirectTo } from 'src/utils';

export interface AlertCardProps {
  lastUpdated: string;
  path: string;
  title: string;
  className?: string;
}

export default function AlertCard(props: AlertCardProps) {
  const { title, lastUpdated, path, className = '' } = props;

  return (
    <div
      onClick={() => hardRedirectTo(path)}
      className={`${styles['alert-card']} ${className}`}
      style={{ cursor: 'pointer' }}
    >
      <article>
        <div className={styles['alert-card__accent']}></div>
        <div className={styles['alert-card__icon']}>
          <IconContactNewsComponent />
        </div>
        <div className={styles['alert-card__content']}>
          <div className={styles['alert-card__title']}>
            <TypographyComponent
              element="h3"
              size={TypographySize.Mdm}
              weight={TypographyWeight.Medium}
            >
              {title}
            </TypographyComponent>
          </div>
          <div className={styles['alert-card__last-updated']}>
            <TypographyComponent size={TypographySize.Xxs}>
              Last updated{' '}
              <HTMLRenderer htmlString={lastUpdated}></HTMLRenderer>
            </TypographyComponent>
          </div>
        </div>
      </article>
    </div>
  );
}
