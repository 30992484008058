import React, { useEffect, useState } from 'react';
import RenderOnScroll from '../render-on-scroll/render-on-scroll.component';
import { APIProvider } from '@vis.gl/react-google-maps';
import styles from './hotels-map.component.module.less';
import GoogleMap from '../google-map/google-map.component';
import { HotelMapMarker } from '../clustered-hotel-markers/clustered-hotel-markers.component';
import { SkeletonLoaderComponent } from '@vaa-component-lib/component.atom.skeleton-loader';
import { TripAdvisorRating } from '@virgin-atlantic/component-lib';

interface HotelProps {
  image: {
    url: string;
    alt: string;
  };
  name: string;
  location?: string;
  tripAdvisor?: {
    rating: TripAdvisorRating;
    reviews?: number;
  };
  virgin?: {
    rating: number;
  };
  details?: string[];
  coordinates?: {
    latitude: number;
    longitude: number;
  };
  link: {
    url: string;
    label: string;
  };
}

interface HotelsMapProps {
  componentId?: string;
  apiKey: string;
  hotels?: HotelProps[];
}

const HotelsMapLoader = () => (
  <SkeletonLoaderComponent loading={true} width="100%">
    <div style={{ height: '638px' }}></div>
  </SkeletonLoaderComponent>
);

export const HotelsMap: React.FC<HotelsMapProps> = ({
  componentId,
  apiKey,
  hotels,
}) => {
  const MAP_ID = 'hotels-map';
  const COMPONENT_ID = componentId || 'hotels-map-component';

  const [coordinates, setCoordinates] = useState<{
    lngs: number[];
    lats: number[];
  }>({ lngs: [], lats: [] });
  const [averageLng, setAverageLng] = useState<number>(0);
  const [averageLat, setAverageLat] = useState<number>(0);
  const [hotelMarkerPositions, setHotelMarkerPositions] = useState<
    HotelMapMarker[]
  >([]);

  useEffect(() => {
    if (!hotels) {
      return;
    }
    const hotelsAsMarkerPositions: HotelMapMarker[] = hotels
      .map((hotel) => {
        return {
          ...hotel,
          key: hotel.name.replace(/ /g, ''),
          category: 'hotel',
          name: hotel.name,
          coordinates: {
            lat: hotel.coordinates?.latitude || 0,
            lng: hotel.coordinates?.longitude || 0,
          },
        };
      })
      .filter(
        (coord) =>
          coord !== null &&
          coord !== undefined &&
          coord.coordinates !== null &&
          coord.coordinates.lat !== 0 &&
          coord.coordinates.lng !== 0
      );

    setHotelMarkerPositions(hotelsAsMarkerPositions);

    const lngs = hotels
      .map((hotel) => hotel.coordinates && hotel.coordinates.longitude)
      .filter((lng) => lng !== null && lng !== undefined);
    const lats = hotels
      .map((hotel) => hotel.coordinates && hotel.coordinates.latitude)
      .filter((lat) => lat !== null && lat !== undefined);

    setCoordinates({ lngs, lats });
  }, [hotels]);

  useEffect(() => {
    const highestLng = Math.max(...coordinates.lngs);
    const lowestLng = Math.min(...coordinates.lngs);
    const highestLat = Math.max(...coordinates.lats);
    const lowestLat = Math.min(...coordinates.lats);
    const averageLng = (highestLng + lowestLng) / 2;
    const averageLat = (highestLat + lowestLat) / 2;

    if (isNaN(averageLng) || isNaN(averageLat)) {
      return;
    }
    setAverageLng(averageLng);
    setAverageLat(averageLat);
  }, [coordinates]);

  if (!apiKey || !hotels || hotels.length === 0) {
    return null;
  }

  return (
    <RenderOnScroll id={COMPONENT_ID} loader={<HotelsMapLoader />}>
      <div id={MAP_ID} className={styles['hotels-map']}>
        <APIProvider apiKey={apiKey}>
          <GoogleMap
            mapId={COMPONENT_ID}
            markerPositions={hotelMarkerPositions}
            defaultCenter={{ lat: averageLat, lng: averageLng }}
          />
        </APIProvider>
      </div>
    </RenderOnScroll>
  );
};

export default HotelsMap;
